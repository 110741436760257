import { Tracker } from '../tracker'
import * as Constants from '../constants'
import { LogObj } from '../types'
import { createSequnceId } from '../utils'

export default function common(tracker: Tracker) {
  const events = [
    Constants.PageViewData,
    Constants.EventCustom,
    // Constants.EventExposure,
    Constants.EventAction,
    Constants.EventVirtualAction,
  ]
  const getSeq = createSequnceId()

  const setSeq = (data: LogObj) => {
    data.superProperties['seq'] = getSeq()
  }

  events.forEach(name => {
    tracker.on(name, setSeq)
  })
}
