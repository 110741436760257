import {
    availableWebsite,
    whitelist,
    defaultConf,
    SiteConf,
    websiteConfig,
    typeSiteConfig,
    siteRegMap,
    SiteType,
} from './config'

/**
 * 获取某个站点配置信息
 * @param website
 */
const getWebsiteConfig = (website: string) => {
    return {
        // @ts-ignore
        ...defaultConf,
        // ...websiteConfig[website] || {},
        ...typeSiteConfig[getSiteType(website)] || {},
        website
    }
}

export const getSiteType = (host: string): SiteType=> {
    for (const [type, regex] of Object.entries(siteRegMap)) {
        if (regex.test(host)) {
            return type as SiteType;
        }
    }
    return 'global';
}

export const isGuest = (host: string):boolean =>{
    return siteRegMap.guest.test(host)
}

export const isCN = (host: string):boolean => {
    return siteRegMap.cn.test(host)
}
export const isGlobal = (host: string):boolean =>{
    return siteRegMap.global.test(host)
}

export const isCap = (host: string):boolean =>{
    return siteRegMap.cap.test(host)
}

export const getEnvironment = (host: string): string =>{
    if(host.endsWith('.klook.com')||(host.endsWith('.klook.cn')&&!host.endsWith('.stage.klook.cn'))){
        return 'prod'
    }
    if(host.endsWith('.klooktest.io')||host.endsWith('.klooktest.cn')||host.endsWith('.klooktest.com')){
        return 'test'
    }
    if(host.endsWith('.stage.klook.io')||host.endsWith('.stage.klook.cn')){
        return 'stage'
    }
    return 'prod'
}

// ["www1-fat.klooktest.com",  "cap1-fat.klooktest.com", "guest1-fat.klooktest.com", "www1-fat.klooktest.cn"]
//
// ["www1-fws.klooktest.com",  "cap1-fws.klooktest.com", "guest1-fws.klooktest.com", "www1-fws.klooktest.cn"]
//
// ["www.klook.com","www.klook.cn","cap.klook.com","guest.klook.com"]
//
// ["www.stage.klook.io","www.stage.klook.cn","guest.stage.klook.io","cap.stage.klook.io"]

// /^(www|guest|cap)([\d]+-.*\.klooktest|stage\.klook|klook)(com|io|cn)/

/**
 * 根据一个链接生成其他链接
 * @param link 原始链接
 * @returns 一个包含所有链接的数组
 */
export function generateOtherLinks(host: string): Record<'guest'|'cap'|'global'| 'cn' | 'merchantGlobal' | 'merchantCn', string> {
    const defaultProdSite = {
        guest: 'guest.klook.com',
        cap: 'cap.klook.com',
        global: 'www.klook.com',
        cn: 'www.klook.cn',
        merchantGlobal: 'merchant.klook.com',
        merchantCn: 'merchant.klook.cn'
    }
    if(getEnvironment(host) === 'prod'){
        return defaultProdSite
    }
    if(getEnvironment(host) === 'test'){
        const other =   host.replace(/^([a-zA-Z]+)(\d+-(fat|uat|dev|fws|ptest).klooktest|.stage.klook|.klook)(.com|.io|.cn)/, "$2");
        const end = host.endsWith('io') ? 'io' : 'com'
        const SiteData = {
            guest: `guest${other}.${end}`,
            cap: `cap${other}.${end}`,
            global: `www${other}.${end}`,
            cn: `www${other}.cn`,
            merchantGlobal: `merchant${other}.${end}`,
            merchantCn: `merchant${other}.cn`,
        }
        return SiteData
    }
    if (getEnvironment(host) === 'stage') {
        return {
            guest: 'guest.stage.klook.io',
            cap: 'cap.stage.klook.io',
            global: 'www.stage.klook.io',
            cn: 'www.stage.klook.cn',
            merchantGlobal: 'merchant.stage.klook.io',
            merchantCn: 'merchant.stage.klook.cn'
        }
    }

    return defaultProdSite;
}

/**
 * 扩展website 配置，比如一些特殊的站点配置，可以合并到站点中来
 * @param site
 * @param config
 */
function extendConfig(site: string, config: Partial<SiteConf>) {
    websiteConfig[site] = config
}

export {
    availableWebsite,
    getWebsiteConfig,
    extendConfig,
    whitelist
}
