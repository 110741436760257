import { getQueryParam, isAndroid, isHos, isHosShell, isiOS } from './utils'

/**
 * Ref: https://github.com/mixpanel/mixpanel-js
 */
class Detector {
  private _opera: boolean
  private _vendor: string

  userAgent: string
  referrer: string
  referringDomain: string
  os: string
  webviewOS: string | null
  browser: string
  browserVersion: number | null
  device: string
  searchEngine: string | null
  screen: {
    width: number
    height: number
  }

  constructor() {
    this._opera = !!(window as any).opera
    this._vendor = window.navigator.vendor

    this.userAgent = window.navigator.userAgent
    this.referrer = window.document.referrer || 'direct'
    this.referringDomain =
      this.referrer !== 'direct'
        ? this.getReferringDomain(this.referrer)
        : 'direct'
    this.os = this.getOS(this.userAgent)
    this.webviewOS = this.getWebviewOS(this.userAgent)
    this.browser = this.getBrowser(this.userAgent, this._vendor, this._opera)
    this.browserVersion = this.getBrowserVersion(
      this.userAgent,
      this._vendor,
      this._opera
    )
    this.device = this.getDevice(this.userAgent)
    this.searchEngine = this.getSearchEngine(this.referrer)
    this.screen = {
      width: window.screen.width,
      height: window.screen.height,
    }
  }

  getReferrer(url?: string) {
    const referrer = window.document.referrer || url || ''
    const referringDomain = this.getReferringDomain(referrer)

    return {
      referrer: referrer || 'direct',
      referringDomain: referringDomain || 'direct',
    }
  }

  getReferrerOfUrl(url?: string) {
    const referrer = url || ''
    const referringDomain = this.getReferringDomain(referrer)

    return {
      referrer: referrer || 'direct',
      referringDomain: referringDomain || 'direct',
    }
  }

  getSearchEngine(referrer: string): string | null {
    if (referrer.search('https?://(.*)google.([^/?]*)') === 0) {
      return 'google'
    } else if (referrer.search('https?://(.*)bing.com') === 0) {
      return 'bing'
    } else if (referrer.search('https?://(.*)yahoo.com') === 0) {
      return 'yahoo'
    } else if (referrer.search('https?://(.*)duckduckgo.com') === 0) {
      return 'duckduckgo'
    } else {
      return null
    }
  }

  getSearchInfo(referrer: string) {
    const search = this.getSearchEngine(referrer)
    const key = search !== 'yahoo' ? 'q' : 'p'
    const result = {} as {
      searchEngine: string
      keyword: string
    }

    if (search !== null) {
      result['searchEngine'] = search

      const keyword = getQueryParam(referrer, key)
      if (keyword.length) {
        result['keyword'] = keyword
      }
    }

    return result
  }

  /**
   * This function detects which browser is running this script.
   * The order of the checks are important since many user agents
   * include key words used in later checks.
   */
  getBrowser(userAgent: string, vendor: string | undefined, opera: boolean) {
    vendor = vendor || '' // vendor is undefined for at least IE9
    if (opera || userAgent.includes(' OPR/')) {
      if (userAgent.includes('Mini')) {
        return 'Opera Mini'
      }
      return 'Opera'
    } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
      return 'BlackBerry'
    } else if (
      userAgent.includes('IEMobile') ||
      userAgent.includes('WPDesktop')
    ) {
      return 'Internet Explorer Mobile'
    } else if (userAgent.includes('SamsungBrowser/')) {
      // https://developer.samsung.com/internet/user-agent-string-format
      return 'Samsung Internet'
    } else if (userAgent.includes('Edge') || userAgent.includes('Edg/')) {
      return 'Microsoft Edge'
    } else if (/HuaweiBrowser/i.test(userAgent)) {
      return 'HuaweiBrowser'
    } else if (userAgent.includes('FBIOS')) {
      return 'Facebook Mobile'
    } else if (userAgent.includes('Chrome')) {
      return 'Chrome'
    } else if (userAgent.includes('CriOS')) {
      return 'Chrome iOS'
    } else if (userAgent.includes('UCWEB') || userAgent.includes('UCBrowser')) {
      return 'UC Browser'
    } else if (userAgent.includes('FxiOS')) {
      return 'Firefox iOS'
    } else if (vendor.includes('Apple')) {
      if (userAgent.includes('Mobile')) {
        return 'Mobile Safari'
      }
      return 'Safari'
    } else if (userAgent.includes('Android')) {
      return 'Android Mobile'
    } else if (userAgent.includes('Konqueror')) {
      return 'Konqueror'
    } else if (userAgent.includes('Firefox')) {
      return 'Firefox'
    } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
      return 'Internet Explorer'
    } else if (userAgent.includes('Gecko')) {
      return 'Mozilla'
    } else {
      return ''
    }
  }

  /**
   * This function detects which browser version is running this script,
   * parsing major and minor version (e.g., 42.1). User agent strings from:
   * http://www.useragentstring.com/pages/useragentstring.php
   */
  getBrowserVersion(
    userAgent: string,
    vendor: string | undefined,
    opera: boolean
  ) {
    const browser = this.getBrowser(userAgent, vendor, opera)
    const versionRegexs: any = {
      'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
      'Microsoft Edge': /Edge?\/(\d+(\.\d+)?)/,
      Chrome: /Chrome\/(\d+(\.\d+)?)/,
      'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
      'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
      Safari: /Version\/(\d+(\.\d+)?)/,
      'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
      Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
      'Opera Mini': /Opera Mini\/(\d+(\.\d+)?)/,
      Firefox: /Firefox\/(\d+(\.\d+)?)/,
      'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
      Konqueror: /Konqueror:(\d+(\.\d+)?)/,
      BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
      'Android Mobile': /android\s(\d+(\.\d+)?)/,
      'Samsung Internet': /SamsungBrowser\/(\d+(\.\d+)?)/,
      'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
      Mozilla: /rv:(\d+(\.\d+)?)/,
      HuaweiBrowser: /HuaweiBrowser\/(\d[\.\d]*)/,
    }

    const regex = versionRegexs[browser]
    if (regex === undefined) {
      return null
    }

    const matches = userAgent.match(regex)
    if (!matches) {
      return null
    }

    return parseFloat(matches[matches.length - 2])
  }

  getOS(userAgent: string) {
    if (/Windows/i.test(userAgent)) {
      if (/Phone/.test(userAgent) || /WPDesktop/.test(userAgent)) {
        return 'Windows Phone'
      }
      return 'Windows'
    } else if (/(iPhone|iPad|iPod)/.test(userAgent)) {
      return 'iOS'
    } else if (isHos()) {
      return 'OpenHarmony'
    } else if (/Android/.test(userAgent)) {
      return 'Android'
    } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
      return 'BlackBerry'
    } else if (/Mac/i.test(userAgent)) {
      return 'Mac OS X'
    } else if (/Linux/.test(userAgent)) {
      return 'Linux'
    } else if (/CrOS/.test(userAgent)) {
      return 'Chrome OS'
    } else {
      return ''
    }
  }

  getAppOs() {
    if (isAndroid()) {
      return 'android'
    } else if (isiOS()) {
      return 'ios'
    } else if (isHosShell()) {
      return 'openharmony-shell'
    } else if (isHos()) {
      return 'openharmony'
    }

    return ''
  }

  getDevice(userAgent: string) {
    if (/Windows Phone/i.test(userAgent) || /WPDesktop/.test(userAgent)) {
      return 'Windows Phone'
    } else if (isHos()) {
      return 'OpenHarmony'
    } else if (/iPad/.test(userAgent)) {
      return 'iPad'
    } else if (/iPod/.test(userAgent)) {
      return 'iPod Touch'
    } else if (/iPhone/.test(userAgent)) {
      return 'iPhone'
    } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
      return 'BlackBerry'
    } else if (/Android/.test(userAgent)) {
      return 'Android'
    } else {
      return ''
    }
  }

  getWebviewOS(userAgent: string) {
    if (/klook/i.test(userAgent)) {
      return this.getAppOs() || null
    }
    return null
  }

  getReferringDomain(referrer: string) {
    const terms = referrer.split('/')
    if (terms.length >= 3) {
      return terms[2]
    }
    return ''
  }

  private isSupportMutationObserver() {
    return typeof MutationObserver != 'undefined'
  }

  private isSupportIntersectionObserver() {
    return typeof IntersectionObserver != 'undefined'
  }

  private isSupportLocalStorage() {
    let flag = true

    try {
      window.localStorage.setItem('__localstorage_test', '1')
      window.localStorage.removeItem('__localstorage_test')
    } catch (e) {
      flag = false
    }
    return flag
  }

  detectBrowser() {
    return {
      supportMutationObserver: this.isSupportMutationObserver(),
      supportIntersectionObserver: this.isSupportIntersectionObserver(),
      supportLocalStorage: this.isSupportLocalStorage(),
    }
  }
}

export default Detector
