import Data from '../types'

export type SiteConf = {
    ip_country: Data.Country
    default_currency: Data.Currency
    default_languages: Data.Language
    support_currencies: Data.Currency[]
    only_server_support_currencies: Data.Currency[]
    support_languages: Data.Language[]
    change_site_tip: boolean
    footer_change_site: boolean
    href: string
    website: string
    business_license: boolean
    pay_way: 'all' | 'china'
    price_guarantee: boolean
    market: 'global' | 'cn'
    market_id: 1 | 2
}

// 默认站点配置 www.klook.com
export const defaultConf: SiteConf = {
    ip_country: 'US',
    default_currency: 'USD',
    default_languages: 'en',
    support_currencies: [
        'HKD',
        'USD',
        'TWD',
        'SGD',
        'CNY',
        'AUD',
        'GBP',
        'LAK',
        'EUR',
        'THB',
        'NZD',
        'MYR',
        'JPY',
        'AED',
        'PHP',
        'KRW',
        'VND',
        'IDR',
        'SEK',
        'NOK',
        'DKK',
        'CHF',
        'TRY',
        'ISK',
        'INR',
        'KHR',
        'MOP',
        'QAR',
        'OMR',
        'JOD',
        'FJD',
        'CAD',
        'MUR',
        'MXN',
        'MAD',
        'EGP',
        'ZAR',
        'MGA',
        'ILS'
    ],

    // 这里配置 只需要在中间件中放行的配置，首次发布新增即可
    only_server_support_currencies: [],

    support_languages: [
        'de',
        'en',
        'en-AU',
        'en-CA',
        'en-GB',
        'en-HK',
        'en-IN',
        'en-MY',
        'en-NZ',
        'en-PH',
        'en-SG',
        'en-US',
        'es',
        'fr',
        'id',
        'it',
        'ja',
        'ko',
        'ru',
        'th',
        'vi',
        'zh-CN',
        'zh-HK',
        'zh-TW',
        'ms-MY'
    ],
    change_site_tip: false,
    footer_change_site: true,
    href: 'https://www.klook.com/',
    website: 'www.klook.com',
    business_license: false, // footer营业执照
    pay_way: 'all',
    price_guarantee: true,
    market: 'global',
    market_id: 1
}

// cn 站点配置
const cnMarketConfig: Partial<SiteConf> = {
    ip_country: 'CN',
    default_currency: 'CNY',
    default_languages: 'zh-CN',
    support_currencies: ['CNY'],
    only_server_support_currencies: [],
    support_languages: ['zh-CN'],
    change_site_tip: true,
    business_license: true,
    pay_way: 'china',
    price_guarantee: false,
    market: 'cn',
    market_id: 2
}

const cnSiteConfig = {
    ...cnMarketConfig,
    href: 'https://www.klook.cn/',
    website: 'www.klook.cn',
}

export const websiteConfig: Record<string, Partial<SiteConf>> = {
    'www.klook.cn': cnSiteConfig,
    'klook.cn': cnSiteConfig,
    'guest.klook.com': {
        website: 'guest.klook.com',
        footer_change_site: false
    },
    'cap.klook.com': {
        website: 'cap.klook.com'
    }
}

export const typeSiteConfig: Record<string, Partial<SiteConf>> = {
    'cn': cnSiteConfig,
    'guest': {
        website: 'guest.klook.com',
        footer_change_site: false
    },
    'cap': {
        website: 'cap.klook.com'
    }
}




// 主要使用在判断是否为主站，用来处理 cn和com站之前的跳转逻辑等
export const availableWebsite = ['www.klook.cn', 'www.klook.com']

// 校验host使用
export const whitelist: Data.WebSite[] = [
    'www.klook.com',
    'www.klook.cn',
    'cap.klook.com',
    'guest.klook.com',
]


export type SiteType = 'cn'| 'global' | 'guest' | 'cap'

type SiteReg = Record<SiteType, RegExp>
export const siteRegMap: SiteReg = {
    'guest': /^(guest[\d]+-(fat|uat|dev|fws|ptest).klooktest.com|guest.klook.com|guest.stage.klook.io)$/,
    'cap': /^(cap[\d]+-(fat|uat|dev|fws|ptest).klooktest.com|cap.klook.com|cap.stage.klook.io)$/,
    'cn': /^(www[\d]+-(fat|uat|dev|fws|ptest).klooktest.cn|www.klook.cn|www.stage.klook.cn|klook.cn)$/,
    'global': /^(www[\d]+-(fat|uat|dev|fws|ptest).klooktest.com|www.klook.com|www.stage.klook.io)$/
}
