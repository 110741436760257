import { Tracker } from '../tracker'
import pageview from './pageview'
import serverTime from './serverTime'
import error from './error'
import common from './common'

export default function plugins(tracker: Tracker) {
  pageview(tracker)
  serverTime(tracker)
  common(tracker)
  error(tracker)
}
